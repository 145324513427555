// Defaults
//import { createRouter, createWebHashHistory } from 'vue-router'

// Here we replace WebHashHistory with WebHistory.
// This gets rid of the retarded /#/$path shit.
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: "dash @ kiyu.moe"
    }
  },
]

const router = createRouter({
  scrollBehavior(to) {
    // Always scroll to top unless otherwise.
    if (to.hash) {
      return {el: to.hash}
    } else {
      return { top: 0 }
    }
  },

  history: createWebHistory(),
  routes
});

export default router
