<template>
    <div v-if="status.website === false || status.media === false || status.request === false || status.arr === false" class="status">
      <a href="#status">
        <div class="flex">
          <img src="@/assets/notice.png" style="max-width:24px;max-height:24px;margin-right:10px;">
          <p>kiyu.moe services might be impaired.</p>
        </div>
        <p class="text-left ml-9" v-if="status.reason">{{ status.reason }}</p>
      </a>
    </div>

    <div v-if="status.applicationsOpen" class="status2">
      <a :href="status.applicationsUrl">
        <div class="flex">
          <img src="@/assets/notice.png" style="max-width:24px;max-height:24px;margin-right:10px;">
          <p>Sign-ups are open for kiyu.moe!</p>
        </div>
        <p class="text-left ml-9" style="font-size:12px;">* Subject to approval, 1 submission per person.</p>
      </a>
    </div>
  <router-view/>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      status: {},
    }
  },

  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'kiyu.moe';
    }
  },

  methods: {
    async getStatus() {
      try {
        await axios.get('https://api.kiyu.moe/api/status')
        .then(response => {
          let data = response.data;
          this.status = data;
        })
      } catch(err) {
        this.status = '429';
      }
    },
  },

  mounted() {
    var data = '<!--彡★彡☆ dzn by spce.moe 彡★彡☆彡-->'
    document.body.insertAdjacentHTML('beforeend', data);
    this.getStatus();
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-color: #323442;
}

.status {
  color: yellow;
  background-color: #613131;
  padding: 16px;
  max-width: 900px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 10px;
  position: fixed;
}
.status2 {
  color: white;
  background-color: rgb(10, 121, 141);
  padding: 16px;
  max-width: 900px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 10px;
  position: fixed;
}
</style>
